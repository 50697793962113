import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import { Button } from '@getvim/atomic-ui';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import AppointmentModal from '../../components-v2/appointment-modal';
import SelectDropdown from '../../components-v2/select-dropdown';
import useApi from '../../hooks/useApi';
import WithApiData from '../../hoks/withApiData';
import { useUserData } from '../../hooks/useUserData';
import CustomToolbar from '../../components-v2/custom-toolbar';
import { isEmpty } from 'lodash-es';

import './index.less';

const localizer = momentLocalizer(moment);

const Calendar = () => {
  const api = useApi();
  const user = useUserData();
  const {
    isAdmin,
    organization: { id: organizationId },
  } = user;

  const [providers, setProviders] = useState<any>([]);
  const [patients, setPatients] = useState<any>([]);
  const [selectedProvider, setSelectedProvider] = useState<any>();
  const [appointmentsModalVisibility, setAppointmentsModalVisibility] = useState<any>(null);
  const [providerSlots, setProviderSlots] = useState<any>([]);

  const [initialOptions, setInitialOptions] = useState<any[]>([]);

  const { formats } = useMemo(
    () => ({
      formats: {
        dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
          `${localizer.format(start, 'MM/DD/YYYY', culture)} - ${localizer.format(
            end,
            'MM/DD/YYYY',
            culture,
          )}`,
      },
    }),
    [],
  );

  useEffect(() => {
    getProviders();
    getPatients();
  }, []);

  useEffect(() => {
    const initialOptions = [...providers];
    isAdmin && initialOptions.push(user);
    setInitialOptions(
      initialOptions.map((provider) => {
        if (!provider?.fullName) {
          return {
            ...provider,
            fullName: `${provider.firstName} ${provider.lastName}`,
          };
        }

        return provider;
      }),
    );

    setSelectedProvider(user);
  }, [providers, user]);

  useEffect(() => {
    if (selectedProvider) {
      getProviderAvailability();
    }
  }, [selectedProvider]);

  const onChange = (newOptions) => {
    if (newOptions?.length) {
      setSelectedProvider(newOptions?.[0]);
    }
  };

  const getProviderAvailability = async (date?: Date) => {
    const provider = [user, ...providers].find(
      (provider: { id: string }) => provider.id === selectedProvider.id,
    );

    const locationId = provider.clinics[0] && provider.clinics[0]?.location?.id;
    if (!locationId) return;

    const providerSlots = await api.getProviderAvailability({
      id: provider.id,
      locationId,
      startDate: moment(date || new Date())
        .startOf('week')
        .toDate(),
    });

    setProviderSlots(providerSlots);
  };

  const getProviders = async (search?: string) => {
    const orgIdFilter = isAdmin ? {} : { organizationId };
    const providers = await api.getProviders({ search, ...orgIdFilter });

    setProviders(providers.data);
  };

  const getPatients = async (search?: string) => {
    const patients = await api.getPatients({ offset: 0, limit: 10, organizationId, search }); // TODO: can't implement pagination. Add search typeahead

    setPatients(patients.data);
  };

  const normalizeEvents = () => {
    return providerSlots.map(
      (slot: {
        startTime: any;
        endTime: any;
        id: number;
        reasonForVisit: string;
        provider: any;
        patient: any;
      }) => {
        return {
          id: slot.id,
          start: new Date(slot.startTime),
          end: new Date(slot.endTime),
          title: customAgenda(slot),
          reasonForVisit: slot.reasonForVisit,
          npi: slot.provider?.npi,
          patientId: slot.patient?.id,
          provider: slot.provider,
          patient: slot.patient,
        };
      },
    );
  };

  const eventStyleGetter = (event: any, start: any, end: any, isSelected: any) => {
    let style = { background: '#8e8c97' };

    if (start.getTime() < new Date().getTime()) {
      // @ts-ignore
      style = { ...style, opacity: '0.5', cursor: 'default' };
    }

    return { style };
  };

  const onSelectEvent = (event: any) => {
    if (event.start.getTime() < new Date().getTime()) return;
    setAppointmentsModalVisibility({ ...event, startTime: event.start });
  };

  const customAgenda = (slot: any) => {
    return (
      <div
        onClick={() =>
          onSelectEvent({
            id: slot.id,
            start: new Date(slot.startTime),
            end: new Date(slot.endTime),
            reasonForVisit: slot.reasonForVisit,
            npi: slot.provider?.npi,
            patientId: slot.patient?.id,
            provider: slot.provider,
            patient: slot.patient,
          })
        }
        className={`custom-agenda-title custom-agenda-title--${
          new Date(slot.startTime).getTime() < new Date().getTime() ? 'disabled' : null
        }`}
      >
        <span>Reason: {slot.reasonForVisit} </span>
        <span>
          Provider: {slot.provider?.firstName} {slot.provider?.lastName}
        </span>
        <span>
          Patient: {slot.patient?.firstName} {slot.patient?.lastName}
        </span>
      </div>
    );
  };

  return (
    <div className="calendar-wrapper gray-bg">
      {appointmentsModalVisibility ? (
        <AppointmentModal
          updateSlots={getProviderAvailability}
          modalVisibility={appointmentsModalVisibility}
          closeModal={() => setAppointmentsModalVisibility(null)}
          providers={[...(isAdmin ? [user] : []), ...providers]}
          patients={patients}
          providerSlots={providerSlots}
          defaultSelectedProvider={selectedProvider}
          getPatients={getPatients}
          getProviders={getProviders}
        />
      ) : null}
      <div className="calendar-controls-container v2">
        <>
          <div className="new-appt-btn">
            <Button
              onClick={() => {
                setAppointmentsModalVisibility({});
              }}
              buttonType="small"
            >
              + New Appointment
            </Button>
          </div>

          {!isEmpty(initialOptions) && (
            <SelectDropdown
              key={selectedProvider?.id}
              valueField="id"
              labelField="fullName"
              label="Filter calendar by provider"
              options={initialOptions}
              onChange={onChange}
              selectedOption={selectedProvider}
            />
          )}
        </>
      </div>
      <BigCalendar
        className="v2"
        localizer={localizer}
        events={normalizeEvents()}
        views={['week', 'agenda']}
        defaultView="week"
        onSelectEvent={onSelectEvent}
        defaultDate={new Date()}
        popup
        min={new Date(0, 0, 0, 6)}
        max={new Date(0, 0, 0, 18)}
        onNavigate={(date: Date) => getProviderAvailability(date)}
        eventPropGetter={eventStyleGetter}
        formats={formats}
        components={{ toolbar: CustomToolbar }}
      />
    </div>
  );
};
export default WithApiData(Calendar);
