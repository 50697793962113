import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { EllipsisText } from '@getvim/atomic-ui';

import Formatter from '../../utils/formatter';
import { useUserData } from '../../hooks/useUserData';
import { Loader } from '@getvim/atomic-ui';
import { Patient } from '../../types';

import './styles.less';
import { IconPen, IconTrash } from '../../assets/icons';
import defaultAvatarImage from '../../assets/icons/icon-profile.svg';
import ConfirmDeleteModal from '../confirm-delete-modal';
import SelectDropdown from '../select-dropdown';
import SearchInput from '../search-input';
import NoResultsFound from '../no-results';
import Paginator from '../paginator';
import { UserRole } from '../../types/user';
import { roleAccess } from '../../utils/roles';
import { debounce } from 'lodash-es';
import dayjs from 'dayjs';

interface PatientsListProps {
  patients: { data: Patient[]; meta: { total: number } };
  organizations: { id: number; alias: string; name: string }[];
  isLoading: boolean;
  getPatients: ({
    offset,
    limit,
    search,
  }: {
    offset: number;
    limit: number;
    search?: string;
  }) => void;
  handleSelect(item: Patient): void;
  activePage: number;
  setActivePage(page: number): void;
  deletePatient(id: string): void;
  setFilter(text: string): void;
  setOrganizationIdFilter(id: number | undefined): void;
}

const PATIENTS_LIMIT = 10;

const PATIENT_FIELD_EMPTY = '-';

const PatientsList = (props: PatientsListProps) => {
  const {
    patients,
    organizations,
    isLoading,
    getPatients,
    handleSelect,
    activePage,
    setActivePage,
    deletePatient,
    setOrganizationIdFilter,
    setFilter,
  } = props;
  const user = useUserData();

  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [patientToBeDeleted, setPatientToBeDeleted] = useState<Patient | null>(null);

  const [initialOptions, setInitialOptions] = useState<any[]>([]);

  useEffect(() => {
    const initialOptions = [ALL_ORGANIZATIONS_OPTION, ...organizations];
    setInitialOptions(initialOptions);
  }, [organizations]);

  const ALL_ORGANIZATIONS_OPTION = {
    name: 'All organizations',
  };
  const [searchOrganization, setSearchOrganization] = useState<any>(ALL_ORGANIZATIONS_OPTION);

  const V2_URL_REGEX = /\/v2\/(\w+)\/patients/;
  const { pathname } = useLocation();
  const v2UrlMatch = pathname.match(V2_URL_REGEX);

  useEffect(() => {
    if (deleteConfirmed && patientToBeDeleted) {
      deletePatient(patientToBeDeleted.id);
      setPatientToBeDeleted(null);
      setDeleteConfirmed(false);
    }
  }, [deleteConfirmed]);

  const getPagesAmount = (itemsAmount: number, pageSize: number): number => {
    const remainder = itemsAmount % pageSize;
    const fullPagesAmount = (itemsAmount - remainder) / pageSize;

    return fullPagesAmount + (remainder ? 1 : 0);
  };

  const debouncedSetFilter = debounce((value?: string) => {
    setFilter(value || '');
  }, 1000);

  const onChange = (newOptions) => {
    if (newOptions?.length) {
      setSearchOrganization(newOptions?.[0]);
      setOrganizationIdFilter(newOptions?.[0]?.id);
    }
  };

  return (
    <div className="patients-list">
      {isLoading && <Loader type="dots" size="small" label="Loading" />}

      <div className="filters-block">
        {roleAccess({ allowed: [UserRole.ADMIN], userRole: user.role }) && (
          <SelectDropdown
            key={searchOrganization?.id}
            valueField="id"
            labelField="name"
            label="Organization"
            options={initialOptions}
            onChange={onChange}
            selectedOption={searchOrganization}
          />
        )}
        <SearchInput
          placeholder="Search patient in list"
          handleChange={(event) => debouncedSetFilter(event.target?.value)}
        />
      </div>

      {!isLoading && Boolean(patients.data?.length) && (
        <>
          <div className="patient-list-titles">
            <div>Name</div>
            <div>DoB</div>
            <div>Gender</div>
            <div>Zipcode</div>
            <div>EHR Insurance</div>
            <div>Member ID</div>
            <div>MRN</div>
            <div>Clinic ID</div>
          </div>
          {patients.data.map((patient: Patient) => {
            const {
              id,
              firstName,
              lastName,
              gender,
              dob,
              profilePicture,
              mrn,
              ehrInsurance,
              memberId,
              location: { zip },
              pcp,
            } = patient;
            const userUrl = v2UrlMatch ? `/v2/${v2UrlMatch[1]}/patient/${id}` : `/patient/${id}`;
            const dobFormatted = dayjs(dob).format('MMM DD, YYYY');

            return (
              <Link to={userUrl} className="patient-item card-style-item" key={`patient-${id}`}>
                <div className="patient-details-copntainer">
                  <div className="patient-avatar">
                    <img
                      src={profilePicture || defaultAvatarImage}
                      alt=""
                      className={profilePicture ? 'patient-img' : 'default-img'}
                    />
                  </div>
                  <div className="patient-text-side">
                    <div className="name">
                      <EllipsisText
                        tooltipContent={Formatter.getFullName(firstName, lastName)}
                        text={Formatter.getFullName(firstName, lastName)}
                        width="large"
                        bgColor="darkBlue"
                      />
                    </div>
                    <div className="dob">{dobFormatted}</div>
                    <div className="gender">{gender || PATIENT_FIELD_EMPTY}</div>
                    <div className="zip-code">{zip || PATIENT_FIELD_EMPTY}</div>
                    <div className="ehrInsurance">
                      <EllipsisText
                        tooltipContent={ehrInsurance || PATIENT_FIELD_EMPTY}
                        text={ehrInsurance || PATIENT_FIELD_EMPTY}
                        width="large"
                        bgColor="darkBlue"
                      />
                    </div>
                    <div className="memberId">
                      <EllipsisText
                        tooltipContent={memberId || PATIENT_FIELD_EMPTY}
                        text={memberId || PATIENT_FIELD_EMPTY}
                        width="large"
                        bgColor="darkBlue"
                      />
                    </div>
                    <div className="mrn">
                      <EllipsisText
                        tooltipContent={mrn || PATIENT_FIELD_EMPTY}
                        text={mrn || PATIENT_FIELD_EMPTY}
                        width="large"
                        bgColor="darkBlue"
                      />
                    </div>
                    <div className="clinic-id">{pcp?.clinics?.[0]?.id || PATIENT_FIELD_EMPTY}</div>
                  </div>
                </div>
                <div className="patient-title__action-btns-wrapper">
                  <div className="patient-title__action-btn card-style-action-btn">
                    <IconPen
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleSelect(patient);
                      }}
                    />
                  </div>
                  <div className="patient-title__action-btn card-style-action-btn">
                    <IconTrash
                      onClick={(e: any) => {
                        e.preventDefault();
                        setPatientToBeDeleted(patient);
                        setConfirmDeleteModalOpen(true);
                      }}
                    />
                  </div>
                </div>
              </Link>
            );
          })}
          <Paginator
            pagesAmount={getPagesAmount(patients.meta.total, PATIENTS_LIMIT)}
            page={Number(activePage)}
            onPageChange={(newPageNumber) => {
              getPatients({ offset: (newPageNumber - 1) * PATIENTS_LIMIT, limit: PATIENTS_LIMIT });
              setActivePage(newPageNumber);
            }}
          />
        </>
      )}
      {!isLoading && !patients.data?.length && <NoResultsFound searchItem="patients" />}

      {confirmDeleteModalOpen && patientToBeDeleted && (
        <ConfirmDeleteModal
          dataToBeDeleted={Formatter.getFullName(
            patientToBeDeleted.firstName,
            patientToBeDeleted.lastName,
          )}
          onConfirmed={() => {
            setDeleteConfirmed(true);
            setConfirmDeleteModalOpen(false);
          }}
          onClose={() => setConfirmDeleteModalOpen(false)}
          isOpen={confirmDeleteModalOpen}
          closeOnBackdrop={false}
        />
      )}
    </div>
  );
};

export default PatientsList;
