import React, { useEffect, useState } from 'react';
import { Box, TextareaAutosize } from '@mui/material';
import { AppointmentType, MetadataType } from '../../types';
import { isString } from 'lodash-es';

import './styles.less';

type SubjectiveProps = {
  selectedAppointment: AppointmentType;
  loading: boolean;
  onChange: (value: { [key: string]: string }) => void;
  isApptLocked: boolean;
};
const GENERAL_NOTES_KEY = 'objective_general_notes';
const PHYSICAL_EXAMINATION_KEY = 'objective_physical_examination';

const Objective: React.FC<SubjectiveProps> = ({ selectedAppointment, onChange, isApptLocked }) => {
  const [generalNotes, setGeneralNotes] = useState<undefined | string>();
  const [physicalExamination, setPhysicalExamination] = useState<undefined | string>();

  const handleGeneralNotesChanges = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setGeneralNotes(event.target.value);
  };
  const handlePhysicalExaminationChanges = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setPhysicalExamination(event.target.value);
  };

  const parseSubjective = (metadata: MetadataType[]) => {
    metadata.forEach(({ key, value }) => {
      switch (key) {
        case GENERAL_NOTES_KEY:
          setGeneralNotes(value);
          break;
        case PHYSICAL_EXAMINATION_KEY:
          setPhysicalExamination(value);
          break;
      }
    });
  };

  useEffect(() => {
    const data = {};

    if (isString(generalNotes)) {
      data[GENERAL_NOTES_KEY] = generalNotes;
    }
    if (isString(physicalExamination)) {
      data[PHYSICAL_EXAMINATION_KEY] = physicalExamination;
    }
    onChange(data);
  }, [generalNotes, physicalExamination]);

  useEffect(() => {
    getSubjective();
  }, [selectedAppointment]);

  const getSubjective = async () => {
    if (selectedAppointment) {
      parseSubjective(selectedAppointment.metadata);
    }
  };

  return (
    <Box className="text-area-wrapper-v2" display="flex" flexDirection="column" flexGrow={1}>
      <span className="main-section-title">Objective</span>
      <Box>
        <div className="title">
          <span>General notes</span>
        </div>
        <TextareaAutosize
          data-test-id="general-notes-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={7}
          value={generalNotes}
          placeholder="Enter notes"
          disabled={isApptLocked}
          onChange={handleGeneralNotesChanges}
        />
      </Box>
      <Box>
        <div className="title">
          <span>Physical examination</span>
        </div>
        <TextareaAutosize
          data-test-id="physical-exam-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={7}
          value={physicalExamination}
          placeholder="Enter notes"
          disabled={isApptLocked}
          onChange={handlePhysicalExaminationChanges}
        />
      </Box>
    </Box>
  );
};

export default Objective;
