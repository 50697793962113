import React, { useEffect, useState } from 'react';
import { Box, TextareaAutosize } from '@mui/material';
import { AppointmentType, MetadataType } from '../../types';
import { isString } from 'lodash-es';

import './styles.less';

type Props = {
  selectedAppointment: AppointmentType;
  loading: boolean;
  onChange: (value: { [key: string]: string }) => void;
  isApptLocked: boolean;
};

const GENERAL_NOTES_PATIENT_INSTRUCTIONS_KEY = 'patient-instructions_general_notes';

const PatientInstructions: React.FC<Props> = ({ selectedAppointment, onChange, isApptLocked }) => {
  const [generalNotes, setGeneralNotes] = useState<undefined | string>();

  const handleGeneralNotesChanges = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setGeneralNotes(event.target.value);
  };

  const parseAppointmentMetaData = (metadata: MetadataType[]) => {
    metadata.forEach(({ key, value }) => {
      if (key === GENERAL_NOTES_PATIENT_INSTRUCTIONS_KEY) {
        setGeneralNotes(value);
      }
    });
  };
  useEffect(() => {
    const data = {};
    if (isString(generalNotes)) {
      data[GENERAL_NOTES_PATIENT_INSTRUCTIONS_KEY] = generalNotes;
    }
    onChange(data);
  }, [generalNotes]);

  useEffect(() => {
    getAppointmentMedataData();
  }, [selectedAppointment]);

  const getAppointmentMedataData = async () => {
    if (selectedAppointment) {
      parseAppointmentMetaData(selectedAppointment.metadata);
    }
  };

  return (
    <Box className="text-area-wrapper-v2" display="flex" flexDirection="column" flexGrow={1}>
      <span className="main-section-title">Patient Instructions</span>
      <Box>
        <div className="title">
          <span>General notes</span>
        </div>
        <TextareaAutosize
          data-test-id="general-notes-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={5}
          value={generalNotes}
          placeholder="Enter notes"
          disabled={isApptLocked}
          onChange={handleGeneralNotesChanges}
        />
      </Box>
    </Box>
  );
};

export default PatientInstructions;
