import React, { useState, useRef, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonGroupComponent } from '@getvim/atomic-ui';
import { Overlay, Popover } from 'react-bootstrap';

import { removeItem } from '../../utils/local-storage';
import { IconProfile2 } from '../../assets/icons';
import './styles.less';

const UserHeaderInfo = ({ name }: { name?: string }) => {
  const ref = useRef(null);

  const [isHeaderMenuOpened, setIsHeaderMenuOpened] = useState<boolean>(false);

  const { logout } = useAuth0();

  const onLogout = useCallback(() => {
    removeItem('access_token');
    removeItem('user');
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const userMenuOptions = [
    {
      value: 'logout',
      text: 'Logout',
      icon: 'icon-logout',
      onClick: onLogout,
    },
  ];

  return (
    <div className="user-header-info-v2" onClick={() => setIsHeaderMenuOpened((prev) => !prev)}>
      <div className="icon-container">
        <IconProfile2 />
      </div>
      <span className="user-name">Dr.&nbsp;{name}</span>
      <div
        ref={ref}
        className={`user-menu-row-arrow ${isHeaderMenuOpened ? 'is-opened' : ''}`}
      ></div>

      <Overlay show={isHeaderMenuOpened} target={ref.current} placement="bottom">
        <Popover className="btn-group-dropdown-popover" id="popover" show={isHeaderMenuOpened}>
          <ButtonGroupComponent
            value=""
            direction="column"
            buttonType="dropdownOption"
            width="block"
            color="extraDarkGray"
            className="styled-button user-menu-icons"
            items={userMenuOptions}
          />
        </Popover>
      </Overlay>
    </div>
  );
};

export default UserHeaderInfo;
