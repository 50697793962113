import { ThemeProvider } from '@mui/material/styles';
import { ThemeVariablesWrapper } from '@getvim/components-hooks-use-theme';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EhrSDKContext } from '../SdkWrapper';
import LogoV2 from '../components-v2/logo';
import SidebarMenu from '../components/sidebar-menu';
import SidebarMenuV2 from '../components-v2/sidebar-menu';
import VimSettings from '../pages-v2/vim-settings';
import { theme } from './style';
import './styles.less';
import { Team, useFeatureFlag } from '@getvim/feature-flags-react';
import { Application } from '../types';
import useApi from '../hooks/useApi';
import { useUserData } from '../hooks/useUserData';

const V2_URL_REGEX = /^\/v2\//;

const mockEhrTheme = {
  className: 'mockEhr',
  fullName: 'mockEhr',
  logo: '//static.getvim.com/img/logos/vimLogoMdTM.png',
  appLogo: '//static.getvim.com/img/logos/VimIconMd.svg',
  font: 'proximaNova , Helvetica, Arial, sans-serif',
  fontWeight: 'bold',
  mainColor: '#001C36',
  secondaryColor: '#09ace4',
  secondaryDarkColor: '#09ace4',
  secondaryColorLight: '#bfdce6',
  secondaryColorLighter: '#d9eaf1',
  buttonColor: '#001C36',
  accentColor: '#e74476',
  secondaryAccentColor: '#f63277',
  linkColor: '#09ace4',
  successColor: '#278600',
  buttonBorderRadius: '4px',
  appTitleFontColor: '#011428',
  appSubTitleFontColor: '#011428b3',
  appBgColor: '#f3f4f9',
  appTitleBgColor: '#e5e5e5',
  appIconColor: '#051427',
  appIconSelectedColor: '#ffffff',
  appIconDisabledColor: '#05142750',
  managementIconColor: '#051427',
};

const Layout: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const user = useUserData();
  const isV2Url = V2_URL_REGEX.test(pathname);

  const { isSdkLoaded } = useContext(EhrSDKContext);
  const { getOrganizationApps } = useApi();
  const [isLoadingApps, setIsLoadingApps] = useState<boolean>(true);
  const [vimSettingsOpen, setVimSettingsOpen] = useState<boolean>(false);
  const [organizationApps, setOrganizationApps] = useState<Application[]>([]);
  const [shouldShowOrgApps, isLoadingFF] = useFeatureFlag({
    flagName: 'mock_ehr.shouldShowOrgApps',
    defaultValue: false,
    team: Team.VimOs,
  });
  const [useUpdatedV2OnRegularRoutes, isV2FFLoading] = useFeatureFlag({
    flagName: 'mock_ehr.useUpdatedV2OnRegularRoutes',
    defaultValue: false,
    team: Team.Interfaces,
    flagContext: {
      vimUserEmail: user.email,
    },
  });

  useEffect(() => {
    if (shouldShowOrgApps) {
      getOrganizationApps().then((response) => {
        setOrganizationApps(response);
        setIsLoadingApps(false);
      });
    }
  }, [getOrganizationApps, shouldShowOrgApps]);

  const openVimSettings = useCallback(() => {
    setVimSettingsOpen(true);
  }, []);
  const closeVimSettings = useCallback(() => {
    setVimSettingsOpen(false);
  }, []);

  const isV2OnTempOrRegularUrl = isV2Url || (!isV2FFLoading && useUpdatedV2OnRegularRoutes);

  return (
    <ThemeProvider theme={theme}>
      <ThemeVariablesWrapper theme={mockEhrTheme}>
        <div className="main-layout">
          <div className={`sidebar ${isV2OnTempOrRegularUrl ? 'v2' : ''}`}>
            <div className="sidebar__header">
              <LogoV2 />
            </div>
            {isV2OnTempOrRegularUrl ? (
              <SidebarMenuV2 isV2TempUrl={isV2Url} openVimSettings={openVimSettings} />
            ) : (
              <SidebarMenu openVimSettings={openVimSettings} />
            )}
          </div>
          {isSdkLoaded && !isLoadingFF && (
            <div className={`drawer ${vimSettingsOpen ? 'open' : ''}`}>
              <VimSettings
                onClose={closeVimSettings}
                shouldShowOrgApps={shouldShowOrgApps}
                isLoadingApps={isLoadingApps && shouldShowOrgApps}
                organizationApps={organizationApps}
              />
            </div>
          )}
          <div className={`page-content ${isV2OnTempOrRegularUrl ? 'v2' : ''}`}>{children}</div>
        </div>
      </ThemeVariablesWrapper>
    </ThemeProvider>
  );
};

export default Layout;
